* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 62.5%;
}

body {
  background-color: #FCFCFC;
}

.mapContainer {
  width: 100rem;
  height: 35rem;
  border-radius: 1rem;
}

@media (max-width: 1070px) {
  .mapContainer {
    width: 95%;
    height: 25rem;
  }
}
